<template>
  <v-stepper v-model="e1">
 <v-stepper-header>
   <v-stepper-step step="1" color="secondary text--primary" class="text-capitalize" :complete="e1 > 1">
 {{$t('contribute')}}
   </v-stepper-step>
   <v-divider></v-divider>
   <v-stepper-step step="2" color="secondary text--primary">
    {{$t('receipt')}}
   </v-stepper-step>
 </v-stepper-header>
 <v-stepper-items>
   <v-stepper-content step="1">
     <v-form ref="form" v-model="valid" lazy-validation>
     <PaymentForm ref="contributionAmt" v-on:last-step="step2Complete" v-on:booking-name="enablePay" />
     </v-form>
     <v-btn rounded color="secondary" class="primary--text mb-2 " v-if="$store.getters.getEventDetails[0].event_capacity > 0" :disabled="!valid || $store.getters.getConfirmBtnStatus || isBookingNameNull" @click="step1Complete">
      <span class="text-capitalize">{{$t('contribute')}}</span>
      <v-icon right>keyboard_arrow_right</v-icon>
     </v-btn>
   </v-stepper-content>

   <v-stepper-content step="2">
       <LandingDonationThankYou receipt :response="$store.getters.getStripeEventResponse" ></LandingDonationThankYou>
     <v-btn rounded color="secondary" class="primary--text ma-2" @click="moreDonation">
     <v-icon left>favorite</v-icon>
      <span class="text-lowercase">{{provideReciept? 'make another contribution' : $t('another_donation')}}</span>
     </v-btn>
   </v-stepper-content>
 </v-stepper-items>
  </v-stepper>
</template>
<script>

export default {
  name: 'landing-donation',
  data: () => ({
    e1: 1,
    valid: false,
    provideReciept: true,
    isBookingNameNull: true,
    hideButton: false
  }),
  components: {
    PaymentForm: () => import(/* webpackChunkName: "PaymentForm" */'../components/PaymentForm'),
    LandingDonationThankYou: () => import(/* webpackChunkName: "PaymentForm" */'../components/LandingDonationThankYou.vue')
  },
  methods: {
    step1Complete () {
      this.$refs.form.validate()
      this.$refs.contributionAmt.saveTransaction()
    },
    step2Complete () {
      console.log('Successful payment')
      this.e1 = 2
    },
    moreDonation () {
      this.e1 = 1
      this.$store.dispatch('enableCancelAction')
    },
    enablePay () {
      this.isBookingNameNull = false
    }
  }
}
</script>
<i18n>
{
  "en":{
    "amount":"Amount",
    "confirmation":"Confirmation",
    "receipt":"Receipt",
    "next":"next",
    "confirm":"confirm",
    "contribute":"contribute",
    "cancel":"cancel",
    "another_donation":"make another donation"
  },
  "fr":{
    "amount":"Montant",
    "confirmation":"Confirmation",
    "receipt":"Reçu",
    "next":"suivant",
    "contribute":"contribuer",
    "confirm":"confirmer",
    "cancel":"annuler",
    "another_donation":"faire un autre don"
  },
  "es":{
    "amount":"Importe",
    "confirmation":"Confirmación",
    "receipt":"Recibo",
    "next":"próximo",
    "contribute":"contribuir",
    "confirm":"confirmar",
    "cancel":"anular",
    "another_donation":"hacer otra donación"
  }
}
</i18n>
